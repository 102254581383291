import { Injectable } from '@angular/core';
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private accessKey: string = environment.accessToken;
  private userKey: string = environment.user;

  constructor() {}

  saveAccessToken(token: string): void {
    localStorage.setItem(this.accessKey, token);
  }

  clearStorage(): void {
    localStorage.clear();
  }

  saveConnectedUser(user: IConnectedUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  getConnectedUser(): IConnectedUser {
    return JSON.parse(localStorage.getItem(this.userKey) ?? '');
  }

  getAccessToken(): string {
    return localStorage.getItem(this.accessKey) ?? '';
  }

}

export interface IConnectedUser {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  role: {id: number, name: string, permissions: any[]}
}
